import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { CSSObject } from 'tss-react';

export type NavigationTab =
    | string
    | {
          path?: string;
          roles?: string[];
          icon?: React.ReactElement;
          children?: NavigationTab[];
          loading?: boolean;
      };

export const setToolbarHeight = (theme: Theme): Record<string, CSSObject> => ({
    [theme.breakpoints.up('md')]: {
        minHeight: '48px',
    },
});
