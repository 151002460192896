import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    NormalizedCacheObject,
    ApolloClientOptions,
    GraphQLRequest,
    InMemoryCacheConfig,
    Operation,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { GraphqlClientName, GraphqlClientNames } from 'common/lib/constants';
import { createUploadLink } from 'apollo-upload-client';
import { getValidAccessToken } from '_embedded_packages/apollo-client/tokenStorage';
import introspectionQueryResultData from '../../fragmentTypes.json';

const defaultTemplateURL =
    process.env.REACT_APP_CLOUD_FUNCTION_API_URL_TEMPLATE;

interface GraphQLLink {
    name: GraphqlClientName;
    templateURL?: string;
    includeCredentials?: boolean;
    batch?: {
        interval: number;
        max: number;
    };
}

function getNameFormats(camelName: string): {
    camel: string;
    kebab: string;
    upperSnake: string;
} {
    const result = camelName.split(/(?=[A-Z])/);

    return {
        camel: camelName,
        kebab: result.map((s) => s.toLocaleLowerCase()).join('-'),
        upperSnake: result.map((s) => s.toLocaleUpperCase()).join('_'),
    };
}

const graphQLLinks: readonly GraphQLLink[] = GraphqlClientNames.map(
    (clientName) => {
        if (clientName === 'default') {
            return {
                name: 'default' as GraphqlClientName,
                templateURL: process.env.REACT_APP_API_URL,
                includeCredentials: true,
                batch: {
                    interval: 100,
                    max: 20,
                },
            };
        }

        if (clientName === 'images') {
            return {
                name: clientName as GraphqlClientName,
                templateURL: defaultTemplateURL,
                batch: {
                    interval: 100,
                    max: 20,
                },
            };
        }

        return {
            name: clientName as GraphqlClientName,
            templateURL: defaultTemplateURL,
        };
    },
);

const cacheOptions: InMemoryCacheConfig = {
    possibleTypes: introspectionQueryResultData,
};

let firstLinkInChain: ApolloLink | undefined;

// This is for conditional batching of queries at the query level (rather than ClientName level)
let firstBatchedLinkInChain: ApolloLink | undefined;

for (const graphQLLink of graphQLLinks) {
    if (!graphQLLink.templateURL) {
        console.error(
            `No templateURL for GraphQL endpoint ${graphQLLink.name} defined.`,
        );
    } else {
        let link: ApolloLink;

        const nameFormats = getNameFormats(graphQLLink.name);

        // check if there is an explicit URL set (e.g. REACT_APP_SURGERY_CALENDAR_API_URL)
        const urlTemplate =
            process.env[`REACT_APP_${nameFormats.upperSnake}_API_URL`] ||
            graphQLLink.templateURL;

        const url = urlTemplate
            .replace('{CLIENT_NAME_CAMEL}', nameFormats.camel)
            .replace('{CLIENT_NAME_KEBAB}', nameFormats.kebab);

        // For conditional batching at query level
        const batchChainLink = new BatchHttpLink({
            uri: url,
            credentials: graphQLLink.includeCredentials ? 'include' : 'omit',
            batchInterval: 100,
            batchMax: 10,
        });

        // For ClientNames that are always batched (e.x. images)
        if (graphQLLink.batch) {
            link = new BatchHttpLink({
                uri: url,
                credentials: graphQLLink.includeCredentials
                    ? 'include'
                    : 'omit',
                batchInterval: graphQLLink.batch.interval,
                batchMax: graphQLLink.batch.max,
            });
        } else {
            link = createUploadLink({
                uri: url,
                credentials: graphQLLink.includeCredentials
                    ? 'include'
                    : 'omit',
            });
        }

        const splitter = (operation: Operation) => {
            return (
                (operation.getContext().clientName === undefined &&
                    graphQLLink.name === 'default') ||
                operation.getContext().clientName === graphQLLink.name
            );
        };

        firstLinkInChain = ApolloLink.split(splitter, link, firstLinkInChain);
        firstBatchedLinkInChain = ApolloLink.split(
            splitter,
            batchChainLink,
            firstBatchedLinkInChain,
        );
    }
}

const linkOptions = {
    uri: process.env.REACT_APP_API_URL,
    credentials: 'include',
};
const fallbackBatchLink = new BatchHttpLink({
    ...linkOptions,
    batchInterval: 100,
    batchMax: 10,
});

const authenticatedLink = setContext(
    ({ variables }: GraphQLRequest, { headers: rawHeaders }: any) => {
        const headers = rawHeaders;
        const context: { [key: string]: unknown } = { headers };
        let batch = false;

        if (variables?.__noCookies === true) {
            context.fetchOptions = { credentials: 'omit' };
        }

        if (variables?.__noAuth) {
            // no need to authenticate (further)
            return context;
        }

        if (variables?.__batch) {
            batch = true;
        }

        return getValidAccessToken().then((accessToken: string) => ({
            headers: {
                ...headers,
                authorization: accessToken ? `Bearer ${accessToken}` : '',
                'Apollo-Require-Preflight': 'true',
            },
            batch,
        }));
    },
);

const link = authenticatedLink.split(
    (operation: Operation) => operation.getContext().batch === true,
    firstBatchedLinkInChain || fallbackBatchLink,
    firstLinkInChain,
);

const cache = new InMemoryCache(cacheOptions);

const clientConfig: ApolloClientOptions<any> = {
    link,
    cache,
};

interface WindowWithApolloState extends Window {
    __APOLLO_STATE__: NormalizedCacheObject;
}

if (
    typeof window !== 'undefined' &&
    (window as unknown as WindowWithApolloState).__APOLLO_STATE__
) {
    cache.restore(
        (window as unknown as WindowWithApolloState).__APOLLO_STATE__,
    );
    clientConfig.ssrForceFetchDelay = 100;
}

export const client = new ApolloClient(clientConfig);
